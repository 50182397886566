
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
});

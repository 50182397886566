import { VDError } from "@/models/VDError";
import { useToast } from "primevue/usetoast";
import { Ref, unref } from "vue";

interface Input {
  severity: string;
  error?: Ref<VDError | null> | VDError | undefined;
  title?: string | undefined;
  message?: string | undefined;
}

export default function () {
  const toast = useToast();

  function show(input: Input) {
    const error = unref(input.error);
    toast.add({
      life: 3000,
      severity: input.severity,
      summary: error ? error.title : input.title,
      detail: error ? error.message : input.message,
    });
  }

  return {
    show: show,
  };
}

import { VDError } from "@/models/VDError";
import { ApolloError } from "@apollo/client/errors";
import { logErrorMessages } from "@vue/apollo-util";
import { computed, Ref, unref } from "vue";
export default function (
  error: (ApolloError | null) | Ref<ApolloError | null>
): Ref<VDError | null> {
  return computed<VDError | null>(() => {
    const uError = unref(error);

    if (process.env.NODE_ENV !== "production" && uError) {
      logErrorMessages(uError);
    }
    return uError
      ? {
          title: "Der skete en fejl",
          message:
            unref(error)?.message ??
            "Vi har desværre ingen yderligere oplysninger på nuværende tidspunkt",
        }
      : null;
  });
}


import { VDError } from "@/models/VDError";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    error: {
      type: Object as PropType<VDError>,
      required: true,
    },
  },
});
